<template>
  <div>
    <div class="card-container faq-setting">
      <SectionTitle
        title="常見問題"
        hint="*數量限制最多三個，請先至「其他頁面設定」進行設定。"
        @edit="openDialog = true"
      />
      <BaseElForm style="margin-top: 22px">
        <BaseElFormItem
          v-for="(config, key, index) in formData"
          :key="index"
          :label="`排序${index+1}`"
        >
          <div class="inner-form-item">
            <p v-if="!config.type" style="color: #AEAEAE !important">尚未選擇</p>
            <p v-if="config.type === 'link'">{{ config.name }}</p>
            <p v-if="config.type === 'link'">{{ config.url }}</p>
            <p v-if="config.type === 'ecommercePage'">{{ findPage(config.ecommercePageId) }}</p>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <SettingDialog
      v-if="openDialog"
      type="faq"
      :configData="faqConfig"
      :pages="pages"
      @close="openDialog = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import SettingDialog from './SettingDialog.vue'
import { clone, find } from 'lodash'

export default {
  name: 'FAQ',
  components: { SectionTitle, SettingDialog },
  props: ['faqConfig', 'pages'],
  data: () => ({
    openDialog: false,
    formData: {},
  }),
  watch: {
    faqConfig () {
      this.syncConfigFormData()
    },
  },

  mounted () {
    this.syncConfigFormData()
  },
  methods: {
    syncConfigFormData () {
      const config = clone(this.faqConfig)
      for (let c = 0; c < 3; c++) {
        this.$set(this.formData, `config${c}`, config[c] || {})
      }
    },

    findPage (pageID) {
      if (!pageID) return ''
      const exist = find(this.pages, page => page.id === pageID)
      if (exist) return exist.title
      return ''
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__content {
  @apply font-normal;
}

.inner-form-item {
  @apply ml-[100px];
}

.faq-setting {
  @apply px-[32px] py-[24px];
}
</style>
